import {
  LoginRNRRouteData,
  getLoginRNRRouteData,
} from "~/server/data/login_rnr_route_data";
import { cache, createAsync, A } from "@solidjs/router";
import { Accessor, createMemo, Match, onMount, Switch } from "solid-js";
import { loginAsset, squidLogo } from "~/assets/assets";
import LoginForm from "~/components/login-rnr/login_form";
import { BrandingMinimal } from "~/widgets/branding";
import { rnrEventManager } from "~/data/events";
import { getClientIdFromCookie } from "~/utils/common";
import { config } from "~/data/config";
import LoginRnrComponentV1 from "~/components/login-rnr/login_rnr_component";
import LoginRnrAxisComponent from "~/components/login-rnr/login_rnr_axis_component";
import HubbleImage from "~/widgets/hubble_image";
import { PhosphorIcon } from "~/widgets/icons";

const getLoginRNRRouteData$C = cache(getLoginRNRRouteData, "login");

const howHubbleCodesWork = [
  "Enter the code above & click “Redeem”",
  "You’ll receive coins in wallet. 1 coin = ₹1",
  "Get the gift card of your choice using coins",
];

export default function LoginRNRRoute() {
  const routeData: Accessor<LoginRNRRouteData | undefined> = createAsync(() =>
    getLoginRNRRouteData$C()
  );

  onMount(() => {
    rnrEventManager.setClientId(getClientIdFromCookie() ?? "");
  });

  const isClientAxis = createMemo(() => {
    return routeData()?.clientId === config.axisClientId;
  });
  const mode = createMemo(() => {
    return routeData()?.mode;
  });
  return (
    <Switch>
      <Match when={isClientAxis()}>
        <LoginRnrAxisComponent routeData={routeData} />
      </Match>
      <Match when={mode() === "squid"}>
        {LoginProcurement({ routeData: routeData })}
      </Match>
      <Match when={true}>
        <LoginRnrComponentV1
          isClientAxis={isClientAxis}
          routeData={routeData}
        />
      </Match>
    </Switch>
  );

  function LoginProcurement(props: {
    routeData: Accessor<LoginRNRRouteData | undefined>;
  }) {
    return (
      <div class="flex h-full w-full">
        <div class="relative h-full w-[50%] bg-gray-400">
          <HubbleImage
            src={loginAsset}
            alt="login-asset"
            errorImage=""
            class="h-full w-full object-cover"
          />
          <div class="absolute left-14 top-10 flex flex-col items-start justify-start ">
            <div class="flex">
              <HubbleImage
                src={squidLogo}
                alt="logo-image"
                errorImage=""
                class="mr-1 h-10 w-10"
              />
              <div class="flex flex-col items-start justify-start">
                <div class="text-bold text-white">SQUID</div>
                <div class="text-f12Bold text-white">By Hubble</div>
              </div>
            </div>
            <div class="mt-11 w-[330px] text-h1 text-white">
              For all your gift card needs
            </div>
            <div class="mt-4 flex items-center justify-start">
              <PhosphorIcon
                name="check"
                fontSize={18}
                class="text-baseTertiaryDark"
              />
              <div class="ml-[6px] text-normal text-baseTertiaryDark">
                450+ brands
              </div>
            </div>
            <div class="mt-4 flex items-center justify-start">
              <PhosphorIcon
                name="check"
                fontSize={18}
                class="text-baseTertiaryDark"
              />
              <div class="ml-[6px] text-normal text-baseTertiaryDark">
                Gest deals & prices
              </div>
            </div>
          </div>
        </div>
        <div class="flex h-full w-[50%] flex-col items-center justify-center align-middle">
          <div class="mb-6 w-[356px] text-start text-h2 text-black">Login</div>
          <div class="h-52">
            <LoginForm
              clientId={() => routeData()?.clientId!}
              clientSecret={() => routeData()?.clientSecret!}
              authMethod={() => routeData()?.authMethod!}
              mode={() => routeData()?.mode!}
            />
          </div>
          <div class="mt-6 text-medium text-textNormal">
            Don’t have an account?{" "}
            <A href="/register">
              <b class="cursor-pointer">Register</b>
            </A>
          </div>
        </div>
      </div>
    );
  }
}
